import React from 'react'
import { Link, useParams } from 'react-router-dom';
import '../../../assets/styles/PostPage.scss'
import '../../../assets/styles/Portfolio.scss'
import { TabGroup } from '../Portfolio/components/Tab';


function PostPage({ data }) {
    const [handleOpenMenu, setHandleOpenMenu] = React.useState(false);
    const { post, category } = useParams()
    const dataWeb = data[0].web
    const dataRobotica = data[0].robotica
    const dataBase = data[0].db
    const dataLogo = data[0].logo


    return (
        <div className='post-page'>
            <div className='portfolio header'>
                <header>
                    <div className="header_wapper fadeInUp" id="header">
                        <div className="header">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-2">
                                        <div className="logo toparrow">
                                            <a href="#home"> Luis </a>
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="right_header ">
                                            <nav id="cssmenu">
                                                <div id="head-mobile">
                                                    <nav id="cssmenu">
                                                        <ul>
                                                            <li>
                                                                <Link
                                                                    className="toparrow"
                                                                    data-hover="Home"
                                                                    to='/portfolio'
                                                                >
                                                                    Home
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className="toparrow"
                                                                    data-hover="About"
                                                                    href='/portfolio/#about'
                                                                >
                                                                    About
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className="toparrow"
                                                                    data-hover="Skills"
                                                                    href="/portfolio/#skills"
                                                                >
                                                                    Skills
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className="toparrow"
                                                                    data-hover="Resume"
                                                                    href="/portfolio/#resume"
                                                                >
                                                                    Resume
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className="toparrow"
                                                                    data-hover="Portfolio"
                                                                    href="/portfolio/#portfolio"
                                                                >
                                                                    Portfolio
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className="toparrow"
                                                                    data-hover="Contact"
                                                                    href="/portfolio/#contact"
                                                                >
                                                                    Contact
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        setHandleOpenMenu((prevTheme) => !prevTheme)
                                                    }
                                                    className={
                                                        handleOpenMenu ? "button menu-opened" : "button"
                                                    }
                                                ></div>
                                                <ul
                                                    style={
                                                        handleOpenMenu
                                                            ? { display: "block" }
                                                            : { display: "none" }
                                                    }
                                                >
                                                    <li>
                                                        <Link
                                                            className="toparrow"
                                                            data-hover="Home"
                                                            to='/portfolio'
                                                        >
                                                            Home
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="toparrow"
                                                            data-hover="About"
                                                            href="/portfolio/#about"
                                                        >
                                                            About
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="toparrow"
                                                            data-hover="Skills"
                                                            href="/portfolio/#skills"
                                                        >
                                                            Skills
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="toparrow"
                                                            data-hover="Resume"
                                                            href="/portfolio/#resume"
                                                        >
                                                            Resume
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="toparrow"
                                                            data-hover="Portfolio"
                                                            href="/portfolio/#portfolio"
                                                        >
                                                            Portfolio
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="toparrow"
                                                            data-hover="Contact"
                                                            href="/portfolio/#contact"
                                                        >
                                                            Contact
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
            <section id="post" className="db_section_top db_portfolio_section portfolio">
                <div className="db_section_top_gap section_bg">
                    <div className="container">

                        <div className='row fadeInUp'>
                            {dataBase.map((dataBase) => {
                                if (category === 'web') {

                                    function isWeb(data) {
                                        return data.title === post;
                                    }

                                    const data = dataWeb.find(isWeb)


                                    return (
                                        <div key={dataBase.id}>
                                            <div className='video row mb-3'>
                                                {data.video ? (
                                                    <>
                                                        <video className='row' height='500' autoPlay controls>
                                                            <source className='row' src={data.video} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </>
                                                )
                                                    :
                                                    (<></>)}
                                            </div>

                                            <div className="row mb-2">
                                                <div className="">
                                                    <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                                        <div className="col-auto d-none d-lg-block">
                                                            <img src={data.image} className="bd-placeholder-img" width="400" />


                                                        </div>
                                                        <div className="col p-4 d-flex flex-column position-static">

                                                            <div className="db_section_title wow fadeInUp mb-0">
                                                                <h3>{post} - {category}</h3>
                                                            </div>
                                                            <h3 className="mb-4 db_section_sub_title">{data.subTitle}</h3>
                                                            {data.description ?
                                                                (
                                                                    <>
                                                                        <p className="card-text mb-auto card_description">{data.description}</p>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        <p className="card-text mb-auto card_description">Em Breve uma descrição !!</p>
                                                                    </>
                                                                )
                                                            }
                                                            {data.link ?
                                                                (
                                                                    <>
                                                                        <a href={data.link} className="stretched-link">Demo Live</a>
                                                                    </>
                                                                )
                                                                :
                                                                (<></>)
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                if (category === 'logos') {

                                    function isLogo(data) {
                                        return data.title === post;
                                    }

                                    const data = dataLogo.find(isLogo)

                                    return (
                                        <div key={dataBase.id}>
                                            <div className='video row mb-3'>
                                                {data.video ? (
                                                    <>
                                                        <video className='row' height='500' autoPlay controls>
                                                            <source className='row' src={data.video} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </>
                                                )
                                                    :
                                                    (<></>)}
                                            </div>

                                            <div className="row mb-2">
                                                <div className="">
                                                    <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                                        <div className="col-auto d-none d-lg-block">
                                                            <img src={data.image} className="bd-placeholder-img" width="400" />


                                                        </div>
                                                        <div className="col p-4 d-flex flex-column position-static">

                                                            <div className="db_section_title wow fadeInUp mb-0">
                                                                <h3>{post} - {category}</h3>
                                                            </div>
                                                            <h3 className="mb-4 db_section_sub_title">{data.subTitle}</h3>
                                                            {data.description ?
                                                                (
                                                                    <>
                                                                        <p className="card-text mb-auto card_description">{data.description}</p>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        <p className="card-text mb-auto card_description">Em Breve uma descrição !!</p>
                                                                    </>
                                                                )
                                                            }
                                                            {data.link ?
                                                                (
                                                                    <>
                                                                        <a href={data.link} className="stretched-link">Demo Live</a>
                                                                    </>
                                                                )
                                                                :
                                                                (<></>)
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                if (category === 'robotica') {

                                    function isRobotica(data) {
                                        return data.title === post;
                                    }
                                    const data = dataRobotica.find(isRobotica)

                                    return (
                                        <div key={dataBase.id}>
                                            <div className='video row mb-3'>
                                                {data.video ? (
                                                    <>
                                                        <video className='row' height='500' autoPlay controls>
                                                            <source className='row' src={data.video} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </>
                                                )
                                                    :
                                                    (<></>)}
                                            </div>

                                            <div className="row mb-2">
                                                <div className="">
                                                    <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                                        <div className="col-auto d-none d-lg-block">
                                                            <img src={data.image} className="bd-placeholder-img" width="400" />


                                                        </div>
                                                        <div className="col p-4 d-flex flex-column position-static">

                                                            <div className="db_section_title wow fadeInUp mb-0">
                                                                <h3>{post} - {category}</h3>
                                                            </div>
                                                            <h3 className="mb-4 db_section_sub_title">{data.subTitle}</h3>
                                                            {data.description ?
                                                                (
                                                                    <>
                                                                        <p className="card-text mb-auto card_description">{data.description}</p>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        <p className="card-text mb-auto card_description">Em Breve uma descrição !!</p>
                                                                    </>
                                                                )
                                                            }
                                                            {data.link ?
                                                                (
                                                                    <>
                                                                        <a href={data.link} className="stretched-link">Demo Live</a>
                                                                    </>
                                                                )
                                                                :
                                                                (<></>)
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>

                        <div className="row">
                            <TabGroup data={data} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PostPage;