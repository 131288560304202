import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./src/assets/bootstrap/css/bootstrap.min.css";
import "./src/assets/styles/Portfolio.scss";
import "./src/assets/styles/Dashboard.scss"
import './App.scss';

import { Portfolio } from "./src/componentes/Pages/Portfolio";
import { Bio } from "./src/componentes/Pages/Bio";

import Data from './src/DataBase.json'
import PostPage from "./src/componentes/Pages/PostPage";
import Post from "./src/componentes/Pages/Post";

function App() {


  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Bio />} />

          <Route path="/portfolio" element={<Portfolio data={Data}/>} />
          
          <Route path="/post" element={<Post data={Data}/>} />

          <Route path="/post/:post/:category" element={<PostPage data={Data}/>} />

          <Route path="*" element={<Bio />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
