import React from 'react'
import { Link } from 'react-router-dom';
import '../../../assets/styles/PostPage.scss'

function Post({ data }) {
    const [handleOpenMenu, setHandleOpenMenu] = React.useState(false);

    const dataWeb = data[0].web
    const dataBase = data[0].db
    const dataRobotica = data[0].robotica
    const dataLogo = data[0].logo
    return (
        <div className='post-page'>
            <div className='portfolio header'>
                <header>
                    <div className="header_wapper fadeInUp" id="header">
                        <div className="header">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-2">
                                        <div className="logo toparrow">
                                            <a href="#home"> Luis </a>
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="right_header ">
                                            <nav id="cssmenu">
                                                <div id="head-mobile">
                                                    <nav id="cssmenu">
                                                        <ul>
                                                            <li>
                                                                <Link
                                                                    className="toparrow"
                                                                    data-hover="Home"
                                                                    to='/portfolio'
                                                                >
                                                                    Home
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className="toparrow"
                                                                    data-hover="About"
                                                                    href='/portfolio/#about'
                                                                >
                                                                    About
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className="toparrow"
                                                                    data-hover="Skills"
                                                                    href="/portfolio/#skills"
                                                                >
                                                                    Skills
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className="toparrow"
                                                                    data-hover="Resume"
                                                                    href="/portfolio/#resume"
                                                                >
                                                                    Resume
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className="toparrow"
                                                                    data-hover="Portfolio"
                                                                    href="/portfolio/#portfolio"
                                                                >
                                                                    Portfolio
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className="toparrow"
                                                                    data-hover="Contact"
                                                                    href="/portfolio/#contact"
                                                                >
                                                                    Contact
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        setHandleOpenMenu((prevTheme) => !prevTheme)
                                                    }
                                                    className={
                                                        handleOpenMenu ? "button menu-opened" : "button"
                                                    }
                                                ></div>
                                                <ul
                                                    style={
                                                        handleOpenMenu
                                                            ? { display: "block" }
                                                            : { display: "none" }
                                                    }
                                                >
                                                    <li>
                                                        <Link
                                                            className="toparrow"
                                                            data-hover="Home"
                                                            to='/portfolio'
                                                        >
                                                            Home
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="toparrow"
                                                            data-hover="About"
                                                            href="/portfolio/#about"
                                                        >
                                                            About
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="toparrow"
                                                            data-hover="Skills"
                                                            href="/portfolio/#skills"
                                                        >
                                                            Skills
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="toparrow"
                                                            data-hover="Resume"
                                                            href="/portfolio/#resume"
                                                        >
                                                            Resume
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="toparrow"
                                                            data-hover="Portfolio"
                                                            href="/portfolio/#portfolio"
                                                        >
                                                            Portfolio
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="toparrow"
                                                            data-hover="Contact"
                                                            href="/portfolio/#contact"
                                                        >
                                                            Contact
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <section id="post" className="db_section_top db_post_section">
                    <div className="db_section_top_gap section_bg">
                        <div className="container">
                            <div className="db_section_title wow fadeInUp">
                                <h2>Post ! !</h2>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-lg-4">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" >
                                        <h2 className="db_section_sub_title wow fadeInUp">Web</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" >

                                        <div className="row justify-content-md-center">
                                            {dataWeb.map((data) => {
                                                return (
                                                    <div className="col-lg-4 col-md-6" style={{ margin: 10 }} key={data.id}>
                                                        <div className="Portfolio">
                                                            <a className="gallery" href={`/post/${data.title}/${data.category}`}>
                                                                <img className="card-img" src={data.image} alt="" />
                                                                <figcaption className="fig-caption">
                                                                    <i className="fa fa-search"></i>
                                                                    <h5 className="title">{data.title}</h5>
                                                                    <span className="sub-title">{data.subTitle}</span>
                                                                </figcaption>
                                                            </a>

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-lg-4">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" >
                                        <h2 className="db_section_sub_title wow fadeInUp">Robótica</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" >

                                        <div className="row justify-content-md-center">
                                            {dataRobotica.map((data) => {
                                                return (
                                                    <div className="col-lg-4 col-md-6" style={{ margin: 10 }} key={data.id}>
                                                        <div className="Portfolio">
                                                            <a className="gallery" href={`/post/${data.title}/${data.category}`}>
                                                                <img className="card-img" src={data.image} alt="" />
                                                                <figcaption className="fig-caption">
                                                                    <i className="fa fa-search"></i>
                                                                    <h5 className="title">{data.title}</h5>
                                                                    <span className="sub-title">{data.subTitle}</span>
                                                                </figcaption>
                                                            </a>

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-lg-4">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" >
                                        <h2 className="db_section_sub_title wow fadeInUp">Interfacer Gráficas</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" >

                                        <div className="row justify-content-md-center">
                                            {dataLogo.map((data) => {
                                                return (
                                                    <div className="col-lg-4 col-md-6" style={{ margin: 10 }} key={data.id}>
                                                        <div className="Portfolio">
                                                            <a className="gallery" href={`/post/${data.title}/${data.category}`}>
                                                                <img className="card-img" src={data.image} alt="" />
                                                                <figcaption className="fig-caption">
                                                                    <i className="fa fa-search"></i>
                                                                    <h5 className="title">{data.title}</h5>
                                                                    <span className="sub-title">{data.subTitle}</span>
                                                                </figcaption>
                                                            </a>

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div >
    )

}

export default Post;