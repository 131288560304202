import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import {
  FaFacebookF,
  FaRegMoneyBillAlt,
  BiWorld,
  BiCoffeeTogo,
  GrLinkedinOption,
  BsHeadphones,
  BsInstagram,
  AiFillApple,
  MdOutlineMovieFilter,
  IoCarSportSharp,
  IoGameControllerOutline,
} from '../../icons'
// import curriculo from "./curriculo2.png"
import curriculoPdf from "./CV_LuisGustavo.pdf"
import perfil from "../../../assets/img/perfil.png"
import about from "../../../assets/img/about.png"
import contact from '../../../assets/img/contact.png'
import resume from '../../../assets/img/resume.png'
import skills from '../../../assets/img/skills.png'

import Work from '../../../assets/img/work.png'
import { TabGroup } from "./components/Tab";


export function Portfolio({ data }) {
  const [handleOpenMenu, setHandleOpenMenu] = React.useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ewldsam', 'template_mpobkfv', form.current, 'user_qIp8CesyQ9ernBNHYWYzt')
      .then((result) => {
        console.log(result.text);

        setName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
      }, (error) => {
        console.log(error.text);
      });
  };



  return (
    <div className="portfolio">
      <header>
        <div className="header_wapper fadeInUp" id="header">
          <div className="header">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-2">
                  <div className="logo toparrow">
                    <a href="#home"> Luis </a>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="right_header ">
                    <nav id="cssmenu">
                      <div id="head-mobile">
                        <nav id="cssmenu">
                          <ul>
                            <li>
                              <a
                                className="toparrow"
                                data-hover="Home"
                                href="#home"
                              >
                                Home
                              </a>
                            </li>
                            <li>
                              <a
                                className="toparrow"
                                data-hover="About"
                                href="#about"
                              >
                                About
                              </a>
                            </li>
                            <li>
                              <a
                                className="toparrow"
                                data-hover="Skills"
                                href="#skills"
                              >
                                Skills
                              </a>
                            </li>
                            <li>
                              <a
                                className="toparrow"
                                data-hover="Resume"
                                href="#resume"
                              >
                                Resume
                              </a>
                            </li>
                            <li>
                              <a
                                className="toparrow"
                                data-hover="Portfolio"
                                href="#portfolio"
                              >
                                Portfolio
                              </a>
                            </li>
                            <li>
                              <a
                                className="toparrow"
                                data-hover="Contact"
                                href="#contact"
                              >
                                Contact
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      <div
                        onClick={() =>
                          setHandleOpenMenu((prevTheme) => !prevTheme)
                        }
                        className={
                          handleOpenMenu ? "button menu-opened" : "button"
                        }
                      ></div>
                      <ul
                        style={
                          handleOpenMenu
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <li>
                          <a
                            className="toparrow"
                            data-hover="Home"
                            href="#home"
                          >
                            Home
                          </a>
                        </li>
                        <li>
                          <a
                            className="toparrow"
                            data-hover="About"
                            href="#about"
                          >
                            About
                          </a>
                        </li>
                        <li>
                          <a
                            className="toparrow"
                            data-hover="Skills"
                            href="#skills"
                          >
                            Skills
                          </a>
                        </li>
                        <li>
                          <a
                            className="toparrow"
                            data-hover="Resume"
                            href="#resume"
                          >
                            Resume
                          </a>
                        </li>
                        <li>
                          <a
                            className="toparrow"
                            data-hover="Portfolio"
                            href="#portfolio"
                          >
                            Portfolio
                          </a>
                        </li>
                        <li>
                          <a
                            className="toparrow"
                            data-hover="Contact"
                            href="#contact"
                          >
                            Contact
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section id="home" className="db_home_slider fadeIn">
        <figcaption>
          <div
            className="tg-bannercontent zoomIn"
            data-tilt=""
            style={{
              willChange:
                "transform, transform: perspective(300px) rotateX(0deg) rotateY(0deg)"
            }}
          >
            <div className="db_banner_contant">
              <div className="db_face_images">
                <div className="circle-ripple"></div>
                <img
                  src={perfil}
                  alt="Perfil"
                  className="img-fluid"
                  style={{
                    borderRadius: "100%",
                    boxShadow: "-1rem 0 3rem rgba(0, 0, 0, 0.2)",
                    marginBottom: "30px",
                    marginTop: "-150px"
                  }}
                />
              </div>
              <div className="db_banner_text">
                <h1>Luis Silva</h1>
                <h2>Developer ( Front-end lover )</h2>
              </div>
              <ul className="social">
                <li>
                  <a
                    target="blank"
                    href="https://www.facebook.com/luisgustavo.gustavo.710/?viewas=&should_open_composer=false&show_switched_toast=false&show_invite_to_follow=false&show_switched_tooltip=false&show_podcast_settings=false&show_community_transition=false&show_community_review_changes=false">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a
                    target="blank"
                    href="https://www.instagram.com/_luis.silvah/"
                  >
                    <BsInstagram />
                  </a>
                </li>
                <li>
                  <a
                    target="blank"
                    href="https://www.linkedin.com/in/luis-gustavo-4b35411ab/"
                  >
                    <GrLinkedinOption />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </figcaption>
      </section>
      <section id="about" className="db_section_top db_about_section">
        <div className="db_section_top_gap">
          <div className="container">
            <div className="db_section_title fadeInUp">
              <img
                className="img-fluid db_section_img"
                src={about}
                alt=""
              />
              <h2>About me</h2>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="db_about_text_wapper db_common_text fadeInUp">

                  <p>
                    <strong>Olá! Eu sou Luis.</strong>
                    Tenho 19 anos, estou atualmente cursando Engenharia de Software na PUC, onde tenho a oportunidade de aplicar meus conhecimentos e habilidades em projetos práticos e desafiadores.
                  </p>
                         <p>
                Com uma base sólida em programação e desenvolvimento de 
                        software, estou entusiasmado para continuar contribuindo com equipes dinâmicas 
                          e projetos inovadores. Minha determinação e comprometimento são atributos 
                            que me impulsionam a sempre buscar novos desafios e aprender constantemente.
                  </p>
                  <p>Muito Obrigado!</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="db_small_title fadeInUp">
                  Personal Details
                </div>
                <div className="db_personal_center">
                  <ul className="db_common_text db_personal_details">
                    <li className="fadeInUp">
                      <strong>Birthdate</strong>17 • 11 • 2004
                    </li>
                    <li className="fadeInUp">
                      <strong>Phone</strong>+55 (19) 971607089
                    </li>
                    <li className="fadeInUp">
                      <strong>Email</strong>luisgsilva21@gmail.com
                    </li>
                    <li className="fadeInUp">
                      <strong>website</strong>
                      <a
                        target="blank" style={{ display: "flex", alignItems: "center", textDecoration: "none", backgroundColor: "transparent", color: "#fff" }} href="https://luissilvah.vercel.app/">luissilvah.vercel.app</a>
                    </li>
                    <li className="fadeInUp">
                      <strong>ADDRESS</strong>Sumaré, SP
                    </li>
                    <li className="fadeInUp">
                      <strong>Job Status</strong>
                      {/* <span>FREELANCE</span> */}
                      <span>JOBLESS</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="db_small_title fadeInUp">
                  My Interests
                </div>
                <div className="db_my_interests db_common_text">
                  <ul>
                    <li className="fadeInUp">
                      <p>
                        <IoGameControllerOutline className="my_interest_icon" />
                        <span>Games</span>
                      </p>
                    </li>
                    <li className="fadeInUp">
                      <p>
                        <BsHeadphones className="my_interest_icon" />
                        <span>Music</span>
                      </p>
                    </li>
                    <li className="fadeInUp">
                      <p>
                        <BiWorld className="my_interest_icon" />
                        <span>Travel</span>
                      </p>
                    </li>
                    <li className="fadeInUp">
                      <p>
                        <AiFillApple className="my_interest_icon" />
                        <span>Mac OS</span>
                      </p>
                    </li>
                    <li className="fadeInUp">
                      <p>
                        <MdOutlineMovieFilter className="my_interest_icon" />
                        <span>Movie</span>
                      </p>
                    </li>
                    <li className="fadeInUp">
                      <p>
                        <BiCoffeeTogo className="my_interest_icon" />
                        <span>Coffee</span>
                      </p>
                    </li>
                    <li className="fadeInUp">
                      <p>
                        <IoCarSportSharp className="my_interest_icon" />
                        <span>Cars</span>
                      </p>
                    </li>
                    <li className="fadeInUp">
                      <p>
                        <FaRegMoneyBillAlt className="my_interest_icon" />
                        <span>Money</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="db_download_cv_button fadeInUp">
              <a
                href={curriculoPdf}
                download="Curriculo"
                target="blank"
                className="btn01"
              >
                Downlaod CV <i className="fa fa-download"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="skills" className="db_section_top db_skills_section">
        <div className="db_section_top_gap section_bg">
          <div className="container">
            <div className="db_section_title fadeInUp">
              <img
                className="img-fluid db_section_img"
                src={skills}
                alt=""
              />
              <h2>Skills</h2>
            </div>
            <div className="row justify-content-around">
              <div className="col-lg-5 col-md-6">
                <div className="db_small_title fadeInUp">
                  Technical Skills
                </div>
                <div className="db_each-skills db_common_text">
                  <div className="candidatos fadeInUp JavaScript">
                    <div className="parcial">
                      <div className="info">
                        <div className="nome">JavaScript</div>
                      </div>
                      <div className="progressBar">
                        <div className="percentagem" style={{ width: "75%" }}>
                          <div className="percentagem-num">75%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="candidatos fadeInUp HTML_CSS">
                    <div className="parcial">
                      <div className="info">
                        <div className="nome">HTML &amp; CSS</div>
                      </div>
                      <div className="progressBar">
                        <div className="percentagem" style={{ width: "89%" }}>
                          <div className="percentagem-num">89%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="candidatos fadeInUp Photoshop">
                    <div className="parcial">
                      <div className="info">
                        <div className="nome">Photoshop</div>
                      </div>
                      <div className="progressBar">
                        <div className="percentagem" style={{ width: "55%" }}>
                          <div className="percentagem-num">55%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="candidatos fadeInUp Wordpress">
                    <div className="parcial">
                      <div className="info">
                        <div className="nome">Wordpress</div>
                      </div>
                      <div className="progressBar">
                        <div className="percentagem" style={{ width: "81%" }}>
                          <div className="percentagem-num">81%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="candidatos fadeInUp Pacote_Ofice">
                    <div className="parcial">
                      <div className="info">
                        <div className="nome">Pacote Ofice</div>
                      </div>
                      <div className="progressBar">
                        <div className="percentagem" style={{ width: "75%" }}>
                          <div className="percentagem-num">75%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="db_small_title fadeInUp">
                  Professional Skills
                </div>
                <div className="db_each-skills db_common_text">
                  <div className="candidatos fadeInUp Communication">
                    <div className="parcial">
                      <div className="info">
                        <div className="nome">Communication</div>
                      </div>
                      <div className="progressBar">
                        <div className="percentagem" style={{ width: "85%" }}>
                          <div className="percentagem-num">85%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="candidatos fadeInUp Teamwork">
                    <div className="parcial">
                      <div className="info">
                        <div className="nome">Teamwork</div>
                      </div>
                      <div className="progressBar">
                        <div className="percentagem" style={{ width: "80%" }}>
                          <div className="percentagem-num">80%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="candidatos fadeInUp Creativity">
                    <div className="parcial">
                      <div className="info">
                        <div className="nome">Creativity</div>
                      </div>
                      <div className="progressBar">
                        <div className="percentagem" style={{ width: "89%" }}>
                          <div className="percentagem-num">89%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="candidatos fadeInUp Dedication">
                    <div className="parcial">
                      <div className="info">
                        <div className="nome">Dedication</div>
                      </div>
                      <div className="progressBar">
                        <div className="percentagem" style={{ width: "85%" }}>
                          <div className="percentagem-num">85%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="candidatos fadeInUp Project_Management">
                    <div className="parcial">
                      <div className="info">
                        <div className="nome">Project Management</div>
                      </div>
                      <div className="progressBar">
                        <div className="percentagem" style={{ width: "74%" }}>
                          <div className="percentagem-num">74%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="resume" className="db_section_top db_resume_section">
        <div className="db_section_top_gap">
          <div className="container">
            <div className="db_section_title fadeInUp">
              <img
                className="img-fluid db_section_img"
                src={resume}
                alt=""
              />
              <h2>My Resume</h2>
            </div>
            <div className="row">
              <div className="col-lg-0">
                <div className="db_small_title text-center">Education</div>
                <div className="db_education_details db_common_text">
                  <div className="db_education_item dark_box_bg fadeInUp">
                    <h4>
                      Técnico em Informática From
                      <Link to="/">{" "}Leandro Fransceschini</Link>
                    </h4>
                    <div className="db_year">2020-2023</div>
                    <ul className="db_common_text">
                      <p>
                        Onde eu aprofundei meus estudos na area da tecnologia.
                      </p>
                      <li>Pacote Ofice</li>
                      <li>Web Site Design</li>
                      <li>HTML &amp; CSS</li>
                      <li>JavaScript</li>
                    </ul>
                  </div>
                  <div className="db_education_item dark_box_bg fadeInUp">
                    <h4>
                      Espanhol From
                      <Link to="/">{" "}Centro de estudo de linguas (CEL)</Link>
                    </h4>
                    <div className="db_year" style={{ marginBottom: 10 }}>2017-2018</div>
                    <h4>
                      Inglês From
                      <Link to="/">{" "}Influx</Link>
                    </h4>
                    <div className="db_year">2015-2018</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="portfolio" className="db_section_top db_portfolio_section">
        <div className="db_section_top_gap section_bg">
          <div className="container">
            <div className="db_section_title wow fadeInUp"> <img className="img-fluid db_section_img" src={Work} alt="" />
              <h2>Portfolio</h2>
            </div>

            <div className="row">
              <TabGroup data={data} />
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="db_section_top ">
        <div className="db_section_top_gap contact_section_wapper">
          <div className="container">
            <div className="db_section_title fadeInUp">
              <img
                className="img-fluid db_section_img"
                src={contact}
                alt=""
              />
              <h2>Contact Me</h2>
            </div>
            <div className="row">
              <div className="col-lg-0">
                <div className="contact_info_section fadeInUp">
                  <form
                    className="contant_form"
                    id="contact-form"
                    onSubmit={sendEmail}
                    ref={form}
                  >
                    <div className="contant_form_row">
                      <input
                        name="name"
                        type="text"
                        placeholder="Your Name"
                        className="input-group"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="contant_form_row">
                      <input
                        name="number"
                        type="text"
                        placeholder="Phone Number"
                        className="input-group"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div className="contant_form_row">
                      <input
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        className="input-group"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="contant_form_row">
                      <input
                        name="subject"
                        type="text"
                        placeholder="Subject"
                        className="input-group"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                    <div className="contant_form_row">
                      <textarea
                        name="message"
                        placeholder="Message"
                        className="textarea-group"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                    <button className="btn01" type="submit">
                      Send Message <i className="fa fa-paper-plane"></i>
                    </button>
                  </form>
                  <br />
                  <p className="form-messege"></p>
                </div>
              </div>
              <div className="map">
                {/* <div className="col-lg-6">
                <div className="google_map_wapper fadeInUp">
                  <div className="google_map_box">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235323.36553072263!2d-47.40138991395898!3d-22.84216845780743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8bcfd64c3182d%3A0x8e575af90f766b31!2zU3VtYXLDqSwgU1A!5e0!3m2!1spt-BR!2sbr!4v1645374776750!5m2!1spt-BR!2sbr"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      style={{border: "0"}}
                      allowFullScreen
                    ></iframe>
                    </div>
                  <div className="google_info_box">
                    <div className="google_info">
                      <ul>
                        <li className="address_icon">
                          Address: Sumaré, SP
                        </li>
                        <li className="call_icon">Call us: 0123 456 7890</li>
                        <li className="email_icon">
                          Email: luisgsilva21@gmail.com
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="footer_wapper">
          <a href="#home" className="cd-top">
            UP
          </a>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="social db_common_text">
                  <ul className="social">
                    <li>
                      <a
                        target="blank"
                        href="https://www.facebook.com/luisgustavo.gustavo.710/?viewas=&should_open_composer=false&show_switched_toast=false&show_invite_to_follow=false&show_switched_tooltip=false&show_podcast_settings=false&show_community_transition=false&show_community_review_changes=false">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        href="https://www.instagram.com/_luis.silvah/"
                      >
                        <BsInstagram />
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        href="https://www.linkedin.com/in/luis-gustavo-4b35411ab/"
                      >
                        <GrLinkedinOption />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="Copyright db_common_text">
                  © Copyright 2021 <strong>Luis</strong>.
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
