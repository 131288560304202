import React from "react";
import { Link } from "react-router-dom";
import {
    BsInstagram,
    GrLinkedinOption,
    MdComputer,
    AiFillGithub,
  } from '../../icons'
import '../../../assets/styles/Bio.scss'
  
import perfil from "../../../assets/img/perfil.png"

export function Bio() {
    return (
      <div className="darkBio">
        <main>
          <div className="container">
            <header className="container_header">
              <div className="profile-image">
                <img
                  src={perfil}
                  className=""
                  alt="Profile_photo"
                /> 
              </div>
              <div className="username">
                <span>Luis Silva</span>
              </div>
              <div className="insta_user">
                <span>@_luis.silvah</span>
              </div>
            </header>
            <div className="container_content">
              <div className="media-social instagram">
                <a
                  target="blank"
                  href="https://www.instagram.com/_luis.silvah/"
                >
                  <BsInstagram className="media-icon" />
                  <span>Instagram</span>
                </a>
              </div>
              <div className="media-social linkedin">
                <a
                  target="blank"
                  href="https://www.linkedin.com/in/luis-gustavo-4b35411ab/"
                >
                  <GrLinkedinOption className="media-icon" />
                  <span>Linkedin</span>
                </a>
              </div>
              <div className="media-social protfolio">
                <Link to="/portfolio">
                  <MdComputer className="media-icon" />
                  <span>Portfólio</span>
                </Link>
              </div>
              <div className="media-social github">
                <a target="blank" href="https://github.com/LuisSilvah">
                  <AiFillGithub
                    className="media-icon"
                    style={{ fontSize: 35 }}
                  />
                  <span>Github</span>
                </a>
              </div>

              {/* <div className="media-social apresentation">
                <a target="blank" href="https://github.com/LuisSilvah">
                  <AiFillYoutube
                    className="media-icon"
                    style={{ fontSize: 35 }}
                  />
                  <span>Apresentação</span>
                </a>
              </div> */}

            </div>
          </div>
        </main>
      </div>
    );
  }