import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Tab = styled.button`
  cursor: pointer;
  ${({ active }) =>
        active &&
        `
    opacity: 1;
    background: #1cb698;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const types = ['web', 'logos', 'robotica'];


export function TabGroup({ data }) {
    const [active, setActive] = useState(types[0]);

    const dataBase = data[0].db
    const web = data[0].web.slice(0, 5)
    const robotica = data[0].robotica.slice(0, 5)
    const logo = data[0].logo.slice(0, 5)

    // const arr = [{ web, robotica, logo }]

    return (
        <>
            <div className="col-lg-12">
                <div className="db_portfolio_menu wow fadeInUp">
                    <ButtonGroup className='nav nav-pills mb-3'>
                        {types.map(type => (
                            <div className='nav-item' key={type}>
                                <Tab
                                    className='btn01'
                                    active={active === type}
                                    onClick={() => setActive(type)}
                                >
                                    {type}
                                </Tab>
                            </div>
                        ))}
                    </ButtonGroup>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="tab-content">
                    <div className="tab-pane fade show active" >

                        {dataBase.map((data) => {
                            if (active === 'logos') {
                                return (
                                    <div key={data.id} className="row justify-content-md-center">
                                        {logo.map((data) => {
                                            return (
                                                <div className="col-lg-4 col-md-6" style={{ margin: 10 }} key={data.id}>
                                                    <div className="Portfolio">
                                                        <a className="gallery" href={`/post/${data.title}/${data.category}`}>
                                                            <img className="card-img" src={data.image} alt="" />
                                                            <figcaption className="fig-caption">
                                                                <i className="fa fa-search"></i>
                                                                <h5 className="title">{data.title}</h5>
                                                                <span className="sub-title">{data.subTitle}</span>
                                                            </figcaption>
                                                        </a>

                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )

                            }
                            else if (active === 'robotica') {

                                return (
                                    <div key={data.id} className="row justify-content-md-center">
                                        {robotica.map((data) => {
                                            return (
                                                <div className="col-lg-4 col-md-6" style={{ margin: 10 }} key={data.id}>
                                                    <div className="Portfolio">
                                                        <a className="gallery" href={`/post/${data.title}/${data.category}`}>
                                                            <img className="card-img" src={data.image} alt="" />
                                                            <figcaption className="fig-caption">
                                                                <i className="fa fa-search"></i>
                                                                <h5 className="title">{data.title}</h5>
                                                                <span className="sub-title">{data.subTitle}</span>
                                                            </figcaption>
                                                        </a>

                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            }
                            else if (active === 'web') {

                                return (
                                    <div key={data.id} className="row justify-content-md-center">
                                        {web.map((data) => {
                                            return (
                                                <div className="col-lg-4 col-md-6" style={{ margin: 10 }} key={data.id}>
                                                    <div className="Portfolio">
                                                        <a className="gallery" href={`/post/${data.title}/${data.category}`}>
                                                            <img className="card-img" src={data.image} alt="" />
                                                            <figcaption className="fig-caption">
                                                                <i className="fa fa-search"></i>
                                                                <h5 className="title">{data.title}</h5>
                                                                <span className="sub-title">{data.subTitle}</span>
                                                            </figcaption>
                                                        </a>

                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            }
                        })}


                        <div className="db_section_title wow fadeInUp">
                            <h2><Link to='/post' className='link_more'>Ver Mais</Link></h2>
                        </div>

                        {/* {data.map((data) => {
                            if (data > 6) {
                                return (
                                    <div className="db_section_title wow fadeInUp" key={data.id}>
                                        <h2><Link to='/post' className='link_more'>Ver Mais</Link></h2>
                                    </div>
                                )
                            }
                            else return (
                                <div className="db_section_title wow fadeInUp" key={data.id}>
                                    <h2><Link to='/post' className='link_more'>bye</Link></h2>
                                </div>
                            )
                        })} */}
                    </div>
                </div>
            </div>
        </>
    );
}

